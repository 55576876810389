@if (
  {
    activities: activities(),
    activitiesWidgets: activitiesWidgets()
  };
  as data
) {
  @if (data.activitiesWidgets && data.activitiesWidgets.length) {
    <div class="widgets-list">
      <!-- {{!!data.activities }}
      {{data.activitiesWidgets|json}} -->

      @for (widget of data.activitiesWidgets; track widget; let i = $index) {
        @if ([widgets.PlayerOfTheDay, widgets.LuckOfTheDay].includes(widget)) {
          @if (!isServer && data.activities) {
            <app-motivator-widget
              [data]="widgetToData(data.activities, widget)"
              (navigateToItem)="navigateToCaseItem($event)"
            ></app-motivator-widget>
          } @else {
            <app-motivator-widget-mock [widget]="widget"> </app-motivator-widget-mock>
          }
        }
        <!-- NOTE-EVENT: added for widget -->
        <!-- @if (i == 1) {
          <app-summer-event-widget />
        } -->

        @if (widget === widgets.Portal && !isServer) {
          <ng-template [cdkPortalOutlet]="customWidgetsPortal$ | async"></ng-template>
        }
      }
    </div>
  }
}
