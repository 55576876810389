import { ChangeDetectionStrategy, Component, input, output, ViewEncapsulation } from '@angular/core';
import { type IBaseWidgetBlock, type ICaseRevisionItem, AgentKeys } from '@dev-fast/types';

import { changeAnimation } from './motivator-widget.animation';

@Component({
  selector: 'app-motivator-widget',
  templateUrl: './motivator-widget.component.html',
  styleUrls: ['./motivator-widget.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
  animations: [changeAnimation],
})
export class MotivatorWidgetComponent {
  readonly data = input<IBaseWidgetBlock>();
  readonly navigateToItem = output<{ skin: string; shortName: string }>();

  protected readonly AgentKeys = AgentKeys;

  navigateToCaseItem(itemRevisions: ICaseRevisionItem): void {
    const skin = itemRevisions.inventoryItem.baseItem.skin;
    const shortName = itemRevisions.inventoryItem.baseItem.shortName;
    if (!skin || !shortName) {
      return;
    }
    const params = {
      skin,
      shortName,
    };
    this.navigateToItem.emit(params);
  }
}
