import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatIconModule } from '@angular/material/icon';
import { TranslateModule } from '@ngx-translate/core';

import { CurrencyComponent } from '@app/core/currency';
import { DropCardComponent, UserAvatarModule } from '@app/ui/components/index';

import { RecentActivitiesService } from '../../recent-activities.service';
import { MotivatorWidgetMockComponent } from './mock/motivator-widget.component.mock';
import { MotivatorWidgetComponent } from './motivator-widget.component';

@NgModule({
  imports: [CommonModule, MatIconModule, TranslateModule, DropCardComponent, CurrencyComponent, UserAvatarModule],
  declarations: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  exports: [MotivatorWidgetComponent, MotivatorWidgetMockComponent],
  providers: [RecentActivitiesService],
})
export class MotivatorWidgetModule {}
