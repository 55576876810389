import type { Portal } from '@angular/cdk/portal';
import { ChangeDetectionStrategy, Component, inject, input } from '@angular/core';
import type { IActivities, IBaseWidgetBlock } from '@dev-fast/types';
import { Widgets } from '@dev-fast/types';
import type { Observable } from 'rxjs';

import { IS_SERVER_TOKEN, skinNameNormalize } from '@app/shared/utils';

import type { RecentActivitiesEngine } from './symbols';
import { RECENT_ACTIVITIES_ENGINE } from './symbols';

@Component({
  selector: 'app-recent-activities',
  templateUrl: './recent-activities.component.html',
  styleUrls: ['./recent-activities.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RecentActivitiesComponent {
  readonly isServer = inject(IS_SERVER_TOKEN);
  readonly #recentActivitiesEngine = inject<RecentActivitiesEngine>(RECENT_ACTIVITIES_ENGINE);
  readonly widgets = Widgets;
  readonly customWidgetsPortal$: Observable<Portal<any> | undefined> = this.#recentActivitiesEngine.customWidgetsPortal$;

  readonly activitiesWidgets = input<Widgets[]>([]);
  readonly activities = input<IActivities | null>(null);

  // @HostBinding('@widgetsMenuComponentAnimation') private animation = true;

  navigateToCaseItem(params: { skin: string; shortName: string }): void {
    if (params.skin && params.shortName) {
      return this.#recentActivitiesEngine.navigateTo([`/skins/item/${skinNameNormalize(params)}`]);
    } else {
      return this.#recentActivitiesEngine.navigateTo(['not-found'], { replaceUrl: true });
    }
  }

  widgetToData(activities: IActivities, widget: string): IBaseWidgetBlock<Record<string, unknown>> | undefined {
    // console.log(activities[widget as keyof IActivities]);
    return activities[widget as keyof IActivities];
  }
}
